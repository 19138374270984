/* Modules */
import { useState, useEffect } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { Link } from 'react-router-dom'
import Fuse from 'fuse.js'

/* Dépendences */
import { productsService } from "../../_services/products.service"

/* Composants */
import Header from "../../composants/Header"
import TitreGammes from "../../composants/TitreGammes"
import Plus from "../../composants/Plus";
import Footer from "../../composants/Footer"
import BoutonScrollToTop from "../../composants/BoutonScrollToTop"

/* Images */
import DrapeauFrance from "../../assets_opti/produits/drapeauFrance_opti.webp"
import DrapeauItalie from "../../assets_opti/produits/drapeauItalie_opti.webp"
import DrapeauEspagne from "../../assets_opti/produits/drapeauEspagne_opti.webp"

/* Code */
export default function Recherche() {
    /* Récupération des données de l'URL */
    let UrlParams = useParams();    
    let produit = UrlParams.produit;
    let recherche = (UrlParams.recherche).slice(7).replace("-", " ");

    let [cards, setCards] = useState([]);
    let [pageIsLoading, setPageIsLoading] = useState(false);

    /* Récupération des cards depuis le localStorage */
    let items = JSON.parse(localStorage.getItem('cards'));

    /* Fonction de récupération des cards */
    function getAllCards() {
        setPageIsLoading(true);
        productsService.getAllCards({})
        .then((res) => {
            setCards(res.data.products);
            localStorage.setItem('cards', JSON.stringify(res.data.products));
            setPageIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
        })
    }

    /* On lance cette fonction à l'initialisation du composant uniquement, pour initialiser les cards */
    useEffect(() => {
        if (items && cards.length === 0) {
            setCards(items);
        } else {
            getAllCards();
        }
    }, [])

    /* Utilisation du module Fuse pour avoir une recherche plus souple */
    const fuseOptions = {
        includeScore: true,
        threshold: 0.4, // Adjust this value to control the fuzziness (0 = exact match, 1 = match anything)
        keys: ['barcode', 'category.display_name', 'category[1].display_name']
    };  
    const fuse = new Fuse(cards, fuseOptions);
    let resultat = fuse.search(recherche).map(result => result.item);

    /* Barre de recherche intégrée */
    const navigate = useNavigate();

    function Recherche(input) {
        recherche = input;
        navigate(`../recherche/valeur=${input}`, { replace: true });
    }
    
    /* Modification du titre et de la description de la page */
    useEffect(() => {
        document.title = `Recherche personnalisée | Maison du Revêtement`;
        document.getElementsByTagName("meta")[2].content= `Filtrer tous les produits proposés par Maison du Revêtement, en utilisant une recherche personnalisée.`;
    }, []);

    return (
        <>
            { pageIsLoading ?
                <div className="page-spinner">
                    <div className="page-spinner_interieur">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            :
                <>
                    <header className={`header header_produits_carrelage`}>
                        <Header />
                        <TitreGammes titre={"Votre Recherche Personnalisée"}/>
                    </header>
                    <main className="resultat">
                        <>
                            <section className="nouvelleRecherche">
                            <div className="nouvelleRecherche_info">
                                <p>Retrouver un produit par son nom ou par sa catégorie d'article !</p>
                                <p>Essayez par exemple : "carrelage", "marbre", "imitation terrazzo", "massif" ou "colle"</p>
                            </div>
                                <div className="nouvelleRecherche_barreDeRecherche">
                                    <input type="search" name="recherche" onInput={evt => Recherche((evt.target.value).replace(" ", "-"))} className="nouvelleRecherche_barreDeRecherche_input" id="barreDeRecherche_input" value={recherche} placeholder="Tapez votre recherche ici !" />
                                    <i className="nouvelleRecherche_barreDeRecherche_loupe fa-solid fa-magnifying-glass"></i>
                                </div>
                            </section>
                            { resultat.length > 0 ?
                                <span className="nb_articles" id="nb_articles">{resultat.length + " articles affichés !"}</span>
                            :
                                <span className="nb_articles" id="nb_articles">Aucun article ne correspond à votre recherche ! Nous ajouterons des produits prochainement.</span>
                            }
                            <section className="listeProduits">
                                { resultat.length > 0 && resultat.map((card) => {
                                    return (
                                        card.id === 74619 ?
                                            null
                                        :
                                            <div className="cardArticle" key={card.id}>
                                                <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="cardArticle_photo">
                                                    <img src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                                    { card.additional_photo !== false ?
                                                        <img className="cardArticle_photo_deux" src={(card.additional_photo).replace("image_256", "image_512")} alt={card.barcode}></img>
                                                    :
                                                        <img className="cardArticle_photo_deux" src={(card.img_url).replace("image_256", "image_512")} alt={card.barcode}></img>
                                                    }
                                                    { card.id === 21466 || card.id === 21468 || card.id === 21469 || card.id === 28166 || card.id === 28168 || card.id === 28169 || card.id === 28170 ?
                                                        <div className="bullePromo">PRIX USINE</div>
                                                    :
                                                        null
                                                    }
                                                    { card.discount !== 0 ?
                                                        <div className="bullePromo">- {card.discount} %</div>     
                                                    :
                                                        null
                                                    }
                                                    { card.stock === true || card.quantityInStock >= 10 ?
                                                        <div className="bulleStock">en stock</div>
                                                        :
                                                        <></>
                                                    }
                                                    { card.provenance === "F" ?
                                                        <div className="drapeau">
                                                            <img src={DrapeauFrance} alt="drapeau de la France"/>
                                                        </div>        
                                                    :
                                                        null
                                                    }
                                                    { card.provenance === "E" ?
                                                        <div className="drapeau">
                                                            <img src={DrapeauEspagne} alt="drapeau de l'Espagne"/>
                                                        </div>        
                                                    :
                                                        null
                                                    }
                                                    { card.provenance === "I" ?
                                                        <div className="drapeau">
                                                            <img src={DrapeauItalie} alt="drapeau de l'Italie"/>
                                                        </div>        
                                                    :
                                                        null
                                                    }
                                                </Link>
                                                <div className="cardArticle_info">
                                                    <Link to={"/gamme/categorie/sous-categorie/article/id=" + card.id} className="cardArticle_info_nom">
                                                        {card.barcode}
                                                    </Link>
                                                    <p className="misEnAvant_bloc_article_info_cat">
                                                        { card.category.display_name.split(" / ")[1] } { card.category.display_name.split(" / ")[2] !== undefined ? " / " + card.category.display_name.split(" / ")[2] : null }
                                                    </p>
                                                    { card.discount !== 0 || card.id === 21466 || card.id === 21468 || card.id === 21469 || card.id === 28166 || card.id === 28168 || card.id === 28169 || card.id === 28170 ?
                                                        <div className="cardArticle_info_prix">
                                                            <p className="cardArticle_info_prix_gauche colorRed">{((card.price_without_taxes * (1 - (card.discount / 100)))).toFixed(2)} €HT{ produit === "accessoires-carrelage" || produit === "accessoires-parquet" ? "" : "/m²" }</p>
                                                            <p className="cardArticle_info_prix_droite colorRed">({((card.price_without_taxes * (1 - (card.discount / 100))) * 1.2).toFixed(2)} €TTC{ produit === "accessoires-carrelage" || produit === "accessoires-parquet" ? "" : "/m²" })</p>
                                                        </div>
                                                    :
                                                        <div className="cardArticle_info_prix">
                                                            <p className="cardArticle_info_prix_gauche">{(card.price_without_taxes).toFixed(2)} €HT{ produit === "accessoires-carrelage" || produit === "accessoires-parquet" ? "" : "/m²" }</p>
                                                            <p className="cardArticle_info_prix_droite">({(card.price_without_taxes * 1.2).toFixed(2)} €TTC{ produit === "accessoires-carrelage" || produit === "accessoires-parquet" ? "" : "/m²" })</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                    )
                                })}
                            </section>
                        </>
                    </main>
                    <Plus />
                    <Footer />
                    <BoutonScrollToTop />
                </>
            }
        </>
    )
}